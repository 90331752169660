// Windows 8 mobile Responsive utility
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
  var msViewportStyle = document.createElement('style')
  msViewportStyle.appendChild(
    document.createTextNode(
      '@-ms-viewport{width:auto!important}'
    )
  )
  document.querySelector('head').appendChild(msViewportStyle)
}

// Jquery functions
jQuery(document).ready(function () {
  // LazyLoading
  jQuery("img").unveil(500, function () {
    jQuery(this).on('load', function () {
      jQuery(this).removeAttr("data-src");
    });
  });
  jQuery(".video__iframe").css("display", "none");
  jQuery(".video__close").click(function(){
    jQuery('#videoOverlay').fadeIn();
    jQuery('#videoContainer').hide();
  });
  jQuery("#videos-slider").slick({
    dots: true
  });
  $('.videos__box > span').balloon({
	css: {
	  border: '1px solid #133595',
	  padding: '10px',
	  fontSize: '16px',
	  fontWeight: 'normal',
	  lineHeight: '160%',
	  backgroundColor: '#FFFFFF',
	  color: '#133595',
	  opacity: 1,
	  boxShadow: 'none',
	  maxWidth: '300px'
	}
  });
  $('.main').on('click', function(ev){
	 $('.videos__box > span').hideBalloon();
  });
});
$(document).ready(function(){
  resizeDiv();
});

window.onresize = function(event) {
  resizeDiv();
}

function resizeDiv() {
  vpw = $(window).width() - 100;
  $(".video > div").css({"height": vpw + "px"});
}
